import { gameNamesDesktop } from "abstract/constants/game-ids"
import { ConsentRow } from "components/userConsentEditor"
import { NotificationsPerGame, SpecialEvent } from "domains/specialEvents/pages/main"

export const configItemTypes = {
  iframes: "iframes",
  buttons: "buttons",
  contests: "contests",
  avatar: "avatar",
  texts: "texts",
  navbarAvatarTabs: "navbarAvatarTabs",
  positionsIngameScreens: "positionsIngameScreens",
  dynamicUI: "dynamicUI",
  inGameBanners: "inGameBanners",
  adsBanners: "adsBanners",
  mobileConfig: "mobileConfig",
  blockedGameModes: "blockedGameModes",
  blockedGameModesV2: "blockedGameModesV2",
  specialEvents: "specialEvents",
  variousConfig: "variousConfig",
  offerwallSettings: "offerwallSettings",
}

export type NameItem = {
  type: ConfigItemType;
  oldName: string;
  newName?: string;
};

export type TypeItem = {
  oldType: ConfigItemType;
  newType?: ConfigItemType;
};

export type ConfigItemAtList<K extends ConfigItemType = ConfigItemType> = ConfigItem<K> & {
  createdAt: Date;
  updatedAt: Date;
};

export type ActionType = "iframe" | "external-link" | "internal-routing";

export type ContestConfigColors = {
  primary: { default: string; light: string; dark: string };
  secondary: { default: string; hover: string; click: string };
  background: { main: string; secondary: string };
  neutral: { inactive: string; black: string; gray: string; red: string };
  text: { inactive: string; white: string; gray: string };
};
export type ContestConfigOnboardingScreenData = {
  title: string;
  text: string;
  image: string;
  consentRows?: ConsentRow[];
};
export type ContestConfigSchedulePageData = {
  image: string;
  title: string;
  text: string;
};

export type ContestConfigLiveData = {
  image: string;
  title: string;
  text: string;
  startDate: Date;
  endDate: Date;
};
export type FreePointType = "share" | "invite" | "newsletter";

export type ContestConfigFreePointsData = {
  type: FreePointType;
  title: string;
  rewardText: string;
  points: number;
  text: string;
  image: string;
  watermark?: string;
  watermarkWidth?: number;
  watermarkPosition?: "center" | "topLeft" | "topRight" | "bottomLeft" | "bottomRight";

  CTA: { activeText: string; inactiveText: string };
  topText?: string;
  highlightsScreen?: {
    header: string;
    description: string;
    CTAtext: string;
  };
  maxFreePoints?: number;
  goalInvite?: number;
};
export type ContestConfigStartgameWindowData = {
  contestImage: string;
};
export type ContestConfigEndgameWindowData = {
  endgameSuccessImage: string;
  endgameFailImage: string;
  endgameFraudImage: string;
  gameBackgroundImage: string;
};
export type RulesSectionType = {
  buttonText?: string;
  header?: string;
  paragraps?: string;
};
export type LoungePageType = {
  loungeSmallImg?: string;
  loungeLargeImg?: string;
  games?: string[];
};

export type TranslationConfig = {
  countryTranslations?: { [key: string]: {[key: string]: string} };
  splashScreen?: {
    title?: string;
    subtitle?: string;
    CTAtext?: string;
    TOCtext?: string;
  };
  onboardingCTA?: {
    continueButton?: string;
    joinButton?: string;
    TOCtext?: string;
  };
  errorStateGlobal?: {
    title?: string;
    subtitle?: string;
    CTAtext?: string;
  };
  schedule?: {
    bigTimerTitle?: string;
    smallTimerTitle?: string;
    getStartedButton?: string;
    tabs?: {
      schedule?: string;
      leaderboard?: string;
      freePoints?: string;
      myRewards?: string;
    };
  };
  leaderboard?: {
    head?: {
      rank?: string;
      nickname?: string;
      points?: string;
      pointsTooltip?: string;
    };
    emptyPage?: {
      title?: string;
      subtitle?: string;
    };
  };
  rewards?: {
    head?: {
      date?: string;
      event?: string;
      reward?: string;
    };
    claimButton?: string;
    claimedButton?: string;
    supportButton?: string;
    faqButton?: string;
    footerText?: string;
    footerAsterixNote?: string;
    emptyPage?: {
      title?: string;
      subtitle?: string;
    };
  };
  invitePopup?: {
    header?: string;
    body?: string;
    disclaimer?: string;
    inviteesLeft?: string;
    completedInvitees?: string;
  };
  tooltipData?: {
    twitterSharingText: string;
    reward?: string;
  };
  rulesSection?: RulesSectionType;
};
export type ITopLadderRewardConfiguration = {
  type?: "BUFF_POINTS" | "GIFT_CARDS";
  rewardText?: string;
  buffPointsAmount?: number;
  giftCardText?: string;
  name?: string;
  transactionName?: string;
};
export type GrandPrizeReward = {
  tooltipText: string
};

export type GrandPrizeRewards = {
  [gameId: string]: GrandPrizeReward[]
}

export type ContestConfig = {
  prettyName?: string;
  topBarLogo?: string;
  contestType?: "CHALLENGE_BASED" | "SCORE_BASED";
  mainLogo?: string;
  navigationLogo?: string;
  settingsLogo?: string;
  advImage?: string;

  countries?: string[];
  services?: string[];
  analyticsImpressionInterval?: number;
  termsAndConditionsLink?: string;
  supportLink?: string;
  faqLink?: string;
  startDate?: Date;
  endDate?: Date;
  colors?: ContestConfigColors;
  distributionType?: "EVERY_WEEK" | "FINAL_WEEK_ONLY" | "ALL-TIME_LEADERBOARD";

  onboarding?: ContestConfigOnboardingScreenData[];

  contestLiveSchedule?: ContestConfigLiveData[];
  contestNotStartedSchedule?: ContestConfigSchedulePageData;
  contestFinishedSchedule?: ContestConfigSchedulePageData & {
    CTAtext: string;
  };
  gameProducerDisclaimer?: string;

  pointAwarding?: PointAwarding;
  contestServerSideConfiguration?: ContestServerSideConfiguration;
  freePoints?: ContestConfigFreePointsData[];
  startgameWindow?: ContestConfigStartgameWindowData;
  endgameWindow?: ContestConfigEndgameWindowData;

  loungePageData?: LoungePageType;
  // games?: string[];
  leaderboardBanner?: string;
  leaderboardTopBanner?: string;
  leaderboardTopBannerCountry?: { [key: string]: string };
  leaderboardAdReplacementBanner?: string;
  leaderboardBannerCountry?: { [key: string]: string };
  leaderboardWithCountry?: boolean;
  leaderboardHasGlobal?: boolean;
  leaderboardBanners?: {
    topBannerCopyText?: string;
    rightBannerCopyText?: string;
  }
  displayAds?: boolean;
  topLadderRewardStrategy?: ITopLadderRewardConfiguration;

  inGameNotificationsRedefine?: boolean;
  // Record<'gameId', Record<'notification-name', ingameNotificationType>>
  inGameNotificationsSettings?: InGameNotificationsSettings;
  notificationsPerGame?: NotificationsPerGame[];
  challengeBasedContest?: {
    topMainPageLeftBanner?: string;
    topMainPageRightBanner?: string;
    rightMainPageBanner?: {
      imageNotCompleted1State: string;
      imageCompleted1State?: string;
      text1State?: string;
      title1State?: string;
      CTAtext1State?: string;

      imageNotCompleteState?: string;
      titleNotCompleteState?: string;
      textNotCompleteState?: string;
      CTAtextNotCompleteState?: string;

      imageGrandState?: string;
      titleGrandState?: string;
      textGrandState?: string;
      CTAtextGrandState?: string;
    };
    leaderboard?: {
      notwin?: ContestConfigSchedulePageData & { CTAtext?: string };
      win?: ContestConfigSchedulePageData & { CTAtext?: string };
      right?: ContestConfigSchedulePageData & { titleFinished?: string };
    };
  };
  grandPrizeRewards?: GrandPrizeRewards
  scheduleSidebarBannerImage?: string
  leaderboardFullheightSidebar?: boolean
  highlightSharing?: boolean
  showAllTimeLeaderboard?: boolean
  recurringNotificationsInterval?: number | null;
  inAppPixels?: Pixel[]
  startgamePixels?: Pixel[]
  endgamePixels?: Pixel[]
  disableChallengesOnStartgameScreen?: boolean
  automaticHighlightRecordingEnabling?: boolean
  disclaimer?: string
  howItWorksTabEnabled?: boolean
  howItWorksContent?: string
  ignoreAdsHiding?: boolean;
} & TranslationConfig;

export type Pixel = {
  src: string;
  firingInterval?: number;
}

export type InGameNotificationsSettings = Record<string, Record<string, ingameNotificationType>>;
type ingameNotificationType = { image: string; status: boolean; value: string, pixels: Pixel[] };

export interface ConfigWithNameType {
  newName?: string;
  newType?: ConfigItemType;
}

export enum SecondaryWindowPositions {
  TOP_LEFT = "TOP_LEFT",
  TOP_CENTER = "TOP_CENTER",
  TOP_RIGHT = "TOP_RIGHT",
  CENTER_LEFT = "CENTER_LEFT",
  CENTER = "CENTER",
  CENTER_RIGHT = "CENTER_RIGHT",
  BOTTOM_LEFT = "BOTTOM_LEFT",
  BOTTOM_CENTER = "BOTTOM_CENTER",
  BOTTOM_RIGHT = "BOTTOM_RIGHT",
}
export enum SecondaryWindowTitle {
  startgame = "startgame",
  endgame = "endgame",
  launchGame = "launchGame",
  secondMonitor = "secondMonitor",
  gameSummary = "gameSummary",
}

export type PositionsIngameScreens = Record<
  keyof typeof gameNamesDesktop,
  Record<SecondaryWindowTitle, SecondaryWindowPositions>
>;

export interface BlockedGameModesV2Config {
  blockedGameModes?: {
    gameId: string,
    exactMatches: string[],
    partialMatches: string[]
  }[]
}

export interface AvatarConfig {
  clipRecording?: boolean;
}

export interface AvatarNavbarTabsConfig {
  discover?: { queryName: string; tabName: string }[];
  highlights?: { queryName: string; tabName: string }[];
}

export interface TextsConfig {
  texts?: { title: string; subtitle: string }[];
}

export interface IFrameSettings {
  tokenRequired?: boolean;
  withBg?: boolean;
  withAd?: boolean;
  withScrollbar?: boolean;
  outsideClickHandler?: boolean;
  title?: string;
  securityGroup?: string;
  url?: string;
  services?: string[];
  dimensions?: {
    width: number;
    height: number;
  };
  position?: {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
  };
}

export interface IFrameButtonsConfig {
  text?: string;
  icon?: string;
  queries?: Record<string, string>;
  action?: ActionType;
  href?: string;
  to?: string;
  iframeName?: string;
}

export interface CommonDynamicUI {
  underMaintenance?: {
    BUFF_DESKTOP?: boolean;
    BUFF_PLAY?: boolean;
    BUFF_MOBILE?: boolean;
  }
}

export interface MobileDynamicUI {
  mobileTopbarMask?: string;
  mobileMarketplaceItemMask?: string;
  mobileSidebarMask?: string;
}

export interface DesktopDynamicUI {
  topbarSettingsImage?: string;
  topbarSettingsMainColor?: string;
  topbarSettingsSecondColor?: string;
  loungeVariousTaskImage?: string;
  avatarMasksImages?: string[];
  cursorImage?: string;
  cursorImagePointer?: string;
  wheelSpinImage?: string;
  wheelSpinImagePaid?: string;
  wheelSpinGuideTextFree?: string;
  wheelSpinGuideTextPaid?: string;
  marketMaskImage?: string;
  backgroundImage?: string;
  backgroundImagePopups?: string;
  marketGeoValueImage?: string;
  marketGeoValueString?: string;
  challengeBannerValueImage?: string;
  challengeBannerCTA?: string;
  buffPayILState?: boolean;
  buffPayILCTA?: string;
  buffPayILValueImage?: string;
  fallAnimation?: boolean;
  fallAnimationImage?: string;
  fallAnimationImageHeight?: string;
  fallAnimationImageCount?: string;
  inviteFriendsRules?: string;
  inviteFriendsTooltipData?: string[];
  paypalOnButtonState?: boolean;
  ingameScreenAdType?: "short" | "tall";
  buffboxImagesLinks?: { openedBox: string, closedBox: string }
  buffboxBackdropImage?: string;
  buffboxBackgroundGlowingBlobs?: string;
  startgameScreenType?: "window" | "notification";
}

export type DynamicUIType = CommonDynamicUI & MobileDynamicUI & DesktopDynamicUI;

export type MobileConfigTab = {
  src: string;
  icon: string;
  name: string;
  iconProps?: { [key: string]: any };
  uid: string;
};

export type MobileConfigHeaderButton = {
  src: string;
  icon: string;
  options?: { [key: string]: any };
  uid: string;
};

export type MobileConfigAnalytics =
  | {
      [key: string]: any;
    }
  | undefined;

export type MobileConfigVariant = {
  tabs: MobileConfigTab[];
  headerButtons?: MobileConfigHeaderButton[];
  analytics?: MobileConfigAnalytics;
};

export type MobileConfigType = {
  variants?: MobileConfigVariant[];
};

export type VariousConfigType = {
  codStartgameAutoclose?: number;
}

export type SpecialEventsConfigType = {
  specialEvents?: SpecialEvent[]
}

export type OfferwallSettings = {
  whitelistedCountries?: string[];
}

export const userTypes = ["ALL", "PREMIUM", "REGULAR"] as const

export const windowTypes = [
  "MAIN_SCREEN",
  "START_GAME",
  "END_GAME",
  "LAUNCH_GAME",
  "GAME_SUMMURY",
  "SECOND_MONITOR",
] as const
export type windowTypesType = typeof windowTypes[number];

export type InGameBannerType = {
  name?: string;
  countries?: string[];
  gameIds?: string[];
  userType?: typeof userTypes[number];
  link?: string;
  windows?: windowTypesType[];
  startDate?: Date;
  endDate?: Date;
  image?: string;
  ignoreAdsHiding?: boolean;
  clickable?: boolean;
  pixels?: Pixel[];
};

export type Response = {
  success: boolean;
  message: string;
  statusCode: number;
};

export interface ConfigItemResponse<T extends ConfigItem = ConfigItem> extends Response {
  item: T["data"];
}

export type ContestServerSideConfiguration = {
  contestTwitterShareMessage: string;
  contestRewardCount: number;
  contestSharingInterval?: number;
};

export type PointAwarding = {
    maxpointsTime: number;
    maxpoints: number;
    secondsToLosePoint: number;
    multiplayerFactor: number;
}

export interface ConfigItemByType {
  iframes: IFrameSettings;
  buttons: IFrameButtonsConfig;
  contests: ContestConfig;
  avatar: AvatarConfig;
  texts: TextsConfig;
  navbarAvatarTabs: AvatarNavbarTabsConfig;
  positionsIngameScreens: PositionsIngameScreens,
  dynamicUI: DynamicUIType,
  inGameBanners: InGameBannerType,
  adsBanners: InGameBannerType,
  mobileConfig: MobileConfigType,
  blockedGameModes: Record<string, string>[],
  blockedGameModesV2: BlockedGameModesV2Config,
  specialEvents: SpecialEventsConfigType,
  variousConfig: VariousConfigType,
  offerwallSettings: OfferwallSettings,
}

export type ConfigItemType = keyof ConfigItemByType;

export interface ConfigItem<K extends ConfigItemType = ConfigItemType> {
  type: K;
  name: string;
  data: ConfigItemByType[K];
}
