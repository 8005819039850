import { gameNamesDesktop } from "abstract/constants/game-ids"
import { FC, useState } from "react"
import { useStore } from "stores/utils/use-store"
import { GameEvent } from "./GameEvent"
import { GameNotificationPicture } from "./GameNotificationPicture"
import { FormInstance } from "antd"
import { GAME_NOTIFICATIONS } from "domains/managingJson/components/EditContest/IngameNotifications/ingameNotifications"
import { ContestConfig } from "domains/managingJson/store/types"

interface props {
  game: string
  form: FormInstance<any>
}

export const GameNotifications: FC<props> = ({ game, form }) => {

  const { dataStore: { managingJsonStore: { selectedElement } } } = useStore()
  const data = selectedElement.data as ContestConfig

  const [, setRerender] = useState(0)

  const changeNotificationStatus = (e, notification: string) => {
    if (!data) {
      return
    }
    if (!data.inGameNotificationsSettings) {
      data.inGameNotificationsSettings = {}
    }
    if (!data.inGameNotificationsSettings[game]) {
      data.inGameNotificationsSettings[game] = {}
    }
    if (!data.inGameNotificationsSettings[game][notification]) {
      data.inGameNotificationsSettings[game][notification] = {
        image: "",
        status: false,
        value: "0",
        pixels: [],
      }
    }
    data.inGameNotificationsSettings[game][notification].status = e
    setRerender(e => e + 1)

  }

  const gameEvents = GAME_NOTIFICATIONS[game] ?? []

  return (

    <div style={{ background: "white", padding: "24px 16px", marginBottom: "24px" }}>
      <div style={{ fontWeight: 500, marginBottom: "16px", textAlign: "center" }}>{gameNamesDesktop[game]}</div>
      {gameEvents.length === 0 ? <div>No events available for this game</div> : (
        <div style={{ display: "flex", background: "white", flexWrap: "wrap" }}>
          <div style={{ width: "31%", marginRight: "10px" }}>
          Events
            <div
              style={{
                width: "100%",
                marginRight: "16px",
                padding: "8px",
                border: "1px solid #F0F0F0",
              }}
            >
              {
                gameEvents.map(event => (
                  <GameEvent game={game} event={event} key={event} changeNotificationStatus={changeNotificationStatus} />
                ))
              }
            </div>
          </div>
          {gameEvents.map(event => {
            if (!data.inGameNotificationsSettings
            || !data.inGameNotificationsSettings[game]
            || !data.inGameNotificationsSettings[game][event]
            || data.inGameNotificationsSettings[game][event]?.status === false) {

              return
            }

            return (
              <div style={{ width: "31%", marginRight: "10px" }} key={event}>
                {event}
                <div style={{ width: "100%", padding: "8px", border: "1px solid #F0F0F0" }}>
                  <GameNotificationPicture game={game} event={event} form={form} />
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
