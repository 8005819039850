import { Checkbox, Input } from "antd"
import { UploadComponent } from "components"
import { PixelsEditorV2 } from "components/PixelsEditorV2"
import { Pixel } from "domains/managingJson/store/types"

type NotificationsComponentProps = {
  game: string;
  title: string;
  inGameNotificationsSettings: any;
  updateData: any;
};

export const NotificationsEditor = ({
  game,
  title,
  inGameNotificationsSettings,
  updateData,
}: NotificationsComponentProps) => {
  return (
    <div
      style={{
        display: "grid",
        gap: "10px",
        backgroundColor: "#f7f7f7",
        padding: 10,
        boxShadow: "1px 1px 12px #0000001c",
        border: "1px solid #ebebeb",
        alignContent: "flex-start",
      }}
    >
      <Checkbox
        checked={inGameNotificationsSettings[game][title]?.status}
        onChange={e => {
          updateData(game, title, "status", e.target.checked)
        }}
      >
        {title} ON
      </Checkbox>
      <UploadComponent
        image={inGameNotificationsSettings[game][title]?.image}
        setImage={(e?: any) => updateData(game, title, "image", e)}
        placeholder={`${title} image`}
        title={`${title} image`}
      />
      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <span style={{ fontWeight: "bold" }}>Value</span>
        <Input
          placeholder="Value"
          type="number"
          min={0}
          value={inGameNotificationsSettings[game][title]?.value}
          onChange={e => updateData(game, title, "value", e.target.value)}
        />
      </div>
      <div>
        <span style={{ fontWeight: "bold" }}>Pixels</span>
        <PixelsEditorV2
          pixels={inGameNotificationsSettings[game][title]?.pixels ?? []}
          onChange={(e: Pixel[]) => updateData(game, title, "pixels", e)}
        />
      </div>
    </div>
  )
}
