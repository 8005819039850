import { FormElement } from "components"
import { ContestConfig } from "domains/managingJson/store/types"
import { FC, useState } from "react"
import { useStore } from "stores/utils/use-store"

interface props {
  game: string
  event: string
  changeNotificationStatus: (e: any, notification: string) => void
}

export const GameEvent: FC<props> = ({ game, event, changeNotificationStatus }) => {

  const { dataStore: { managingJsonStore: { selectedElement } } } = useStore()
  const data = selectedElement.data as ContestConfig

  const [checked, setChecked] = useState(data?.inGameNotificationsSettings
    && data?.inGameNotificationsSettings[game]
    ?data?.inGameNotificationsSettings[game][event]?.status : false)

  return (

    <div style={{ background: "white", padding: "8px 16px", marginBottom: "8px" }}>
      <div style={{ display: "flex", alignItems: "start" }}>
        <input
          style={{ marginRight: "10px", marginTop: "10px" }}
          type="checkbox"
          id={`${game}${event}`}
          name={`${game}${event}`}
          checked={checked}
          onChange={e => {
            setChecked(e.target.checked)
            changeNotificationStatus(e.target.checked, event)
          }}
        />
        <FormElement
          props={{
            onChange: e => {
              // eslint-disable-next-line max-len
              if (data && data.inGameNotificationsSettings && data.inGameNotificationsSettings[game] && data.inGameNotificationsSettings[game][event]) {
                data.inGameNotificationsSettings[game][event].value = e.target.value
              }
            },
          }}
          type="input"
          label={event}
          name={`${game}${event}`}
          initialValue={data?.inGameNotificationsSettings
            &&data?.inGameNotificationsSettings[game]
            ? data?.inGameNotificationsSettings[game][event]?.value : ""}
        />
      </div>
    </div>
  )
}
