import React, { FC, useEffect } from "react"

import { Checkbox } from "antd"
import { InGameNotificationsSettings } from "domains/managingJson/store/types"

import { returnNewSettingsValue } from "./returnNewSettingsValue"
import { GameSettings } from "./renderGameSettings"

export const IngameNotifications: FC<{
  form: any;
  opened: boolean;
  inGameNotificationsSettings: InGameNotificationsSettings;
  setinGameNotificationsSettings: (data: InGameNotificationsSettings) => void;
  redefine: boolean;
  setredefine: (data: boolean) => void;
}> = ({
  form,
  opened,
  inGameNotificationsSettings,
  setinGameNotificationsSettings,
  redefine,
  setredefine,
}) => {
  useEffect(() => {
    if (redefine) {
      const newSettings: InGameNotificationsSettings = {}
      form.getFieldValue(["loungePageData", "games"])?.map(e => {
        returnNewSettingsValue(e, newSettings)
      })
      setinGameNotificationsSettings(newSettings)
    } else {
      setinGameNotificationsSettings({})
    }
  }, [redefine, form, setinGameNotificationsSettings])

  useEffect(() => {
    if (opened) {
      const newSettings: InGameNotificationsSettings = {}

      form.getFieldValue(["loungePageData", "games"])?.map(e => {
        if (inGameNotificationsSettings[e]) {
          newSettings[e] = inGameNotificationsSettings[e]

          return newSettings
        } else {
          returnNewSettingsValue(e, newSettings)
        }

        return null
      })
      setinGameNotificationsSettings(newSettings)
    }
  }, [opened])

  return (
    <div>
      <Checkbox checked={redefine} onChange={() => setredefine(!redefine)}>
        inGame Notifications Redefine?
      </Checkbox>
      {redefine && (
        <div>
          <GameSettings
            inGameNotificationsSettings={inGameNotificationsSettings}
            setinGameNotificationsSettings={setinGameNotificationsSettings}
          />
        </div>
      )}
    </div>
  )
}
