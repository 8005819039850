import React, { Dispatch, FC, SetStateAction, useState } from "react"

import { Button, Form, Input, Typography } from "antd"
import MobileView from "./mobile-view"
import s from "./styles.module.css"

export const PrizeSection: FC<{
  data?: string;

  setparagraps: Dispatch<SetStateAction<string | undefined>>;
}> = ({ data, setparagraps }) => {
  const [description, setDescription] = useState("")
  const [listDescriptions, setListDescription] = useState<string[]>(data ? [data] : [])
  const [uploadText, setUploadText] = useState(data || "")
  const [isShowDevice, setIsShowDevice] = useState(false)

  const addParagraphOrItemList = (symbol: string, withoutText?: boolean) => {
    const concat = withoutText ? symbol : symbol.concat(description)
    listDescriptions.push(concat)
    setUploadText(listDescriptions.join(""))
    setparagraps(listDescriptions.join(""))
    if (!withoutText) {
      setDescription("")
    }
  }

  return (
    <div className={s.wrapper}>
      <Form.Item label="Prize section" required>
        <Input.TextArea
          value={description}
          autoSize={{ minRows: 4 }}
          onChange={e => setDescription(e.target.value)}
          maxLength={500}
        />
      </Form.Item>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
          marginBottom: "10px",
        }}
      >
        <Button
          className={s.button}
          onClick={() => addParagraphOrItemList("**green-prize!", true)}
        >
          Green prize
        </Button>
        <Button
          className={s.button}
          onClick={() => addParagraphOrItemList("**gray-prize!", true)}
        >
          Gray prize
        </Button>
        <Button className={s.button} onClick={() => addParagraphOrItemList("**coin-icon!", true)}>
          Coin-Buff icon
        </Button>

        <Button
          className={s.button}
          onClick={() => addParagraphOrItemList("**wrap-new-line!", true)}
        >
          Wrap to new line
        </Button>

        <Button className={s.button} onClick={() => addParagraphOrItemList("**green-text!")}>
          Green text
        </Button>
        <Button className={s.button} onClick={() => addParagraphOrItemList("**gray-text!")}>
          Gray text
        </Button>
        <Button className={s.button} onClick={() => addParagraphOrItemList("**white-text!")}>
          White text
        </Button>
      </div>

      <Button
        className={s.button}
        onClick={() => {
          setListDescription([])
          setUploadText("")
        }}
      >
        Clear Description
      </Button>

      <Typography.Paragraph>{uploadText}</Typography.Paragraph>

      <Button
        onClick={() => {
          setIsShowDevice(prevState => !prevState)
        }}
        style={{ marginLeft: "auto", display: "grid" }}
        type="primary"
      >
        {isShowDevice ? "Hide" : "Show"} how it looks
      </Button>
      {isShowDevice && <MobileView uploadText={uploadText} />}
      <div style={{ marginBottom: "20px" }} />
    </div>
  )
}
