import { StructureDefinition } from "components/structuredJsonInput"

export const paramStructuresByType: Record<string, StructureDefinition> = {
  revu: {
    id: "revu",
    definition: {
      numOffers: { type: "number", label: "Max Offers" },
      wallId: { type: "string", label: "Wall ID" },
    },
  },
}

export const providerNamesByType = { revu: "RevU" }

