import {
  LOL_NOTIFICATIONS,
  FORTNITE_NOTIFICATIONS,
  DOTA_NOTIFICATIONS,
  VALORANT_NOTIFICATIONS,
  MINECRAFT_NOTIFICATIONS,
  FALL_GUYS_NOTIFICATIONS,
  ROCKET_LEAGUE_NOTIFICATIONS,
  HALO_NOTIFICATIONS,
  HEARTHSTONE_NOTIFICATIONS,
  SPLITGATE_NOTIFICATIONS,
  R6_NOTIFICATIONS, WARZONE_NOTIFICATIONS,
  CALL_OF_DUTY_MW2_NOTIFICATIONS,
  CALL_OF_DUTY_MW3_NOTIFICATIONS,
  CALL_OF_DUTY_WARZONE2_NOTIFICATIONS,
} from "./ingameNotifications"

export const returnNewSettingsValue = (e: string, newSettings: any) => {
  if (e === "5426") {
    return LOL_NOTIFICATIONS.map(item => {
      if (newSettings[e]) {
        newSettings[e][item] = { image: "", status: true, value: "" }
      } else {
        newSettings[e] = { [item]: { image: "", status: true, value: "" } }
      }

      return null
    })
  }

  if (e === "21216") {
    return FORTNITE_NOTIFICATIONS.map(item => {
      if (newSettings[e]) {
        newSettings[e][item] = { image: "", status: true, value: "" }
      } else {
        newSettings[e] = { [item]: { image: "", status: true, value: "" } }
      }

      return null
    })
  }

  if (e === "8032") {
    return MINECRAFT_NOTIFICATIONS.map(item => {
      if (newSettings[e]) {
        newSettings[e][item] = { image: "", status: true, value: "" }
      } else {
        newSettings[e] = { [item]: { image: "", status: true, value: "" } }
      }

      return null
    })
  }

  if (e === "21640") {
    return VALORANT_NOTIFICATIONS.map(item => {
      if (newSettings[e]) {
        newSettings[e][item] = { image: "", status: true, value: "" }
      } else {
        newSettings[e] = { [item]: { image: "", status: true, value: "" } }
      }

      return null
    })
  }
  if (e === "7314") {
    return DOTA_NOTIFICATIONS.map(item => {
      if (newSettings[e]) {
        newSettings[e][item] = { image: "", status: true, value: "" }
      } else {
        newSettings[e] = { [item]: { image: "", status: true, value: "" } }
      }

      return null
    })
  }
  if (e === "42424") {
    return FALL_GUYS_NOTIFICATIONS.map(item => {
      if (newSettings[e]) {
        newSettings[e][item] = { image: "", status: true, value: "" }
      } else {
        newSettings[e] = { [item]: { image: "", status: true, value: "" } }
      }

      return null
    })
  }
  if (e === "10798") {
    return ROCKET_LEAGUE_NOTIFICATIONS.map(item => {
      if (newSettings[e]) {
        newSettings[e][item] = { image: "", status: true, value: "" }
      } else {
        newSettings[e] = { [item]: { image: "", status: true, value: "" } }
      }

      return null
    })
  }

  if (e === "21854") {
    return HALO_NOTIFICATIONS.map(item => {
      if (newSettings[e]) {
        newSettings[e][item] = { image: "", status: true, value: "" }
      } else {
        newSettings[e] = { [item]: { image: "", status: true, value: "" } }
      }

      return null
    })
  }
  if (e === "9898") {
    return HEARTHSTONE_NOTIFICATIONS.map(item => {
      if (newSettings[e]) {
        newSettings[e][item] = { image: "", status: true, value: "" }
      } else {
        newSettings[e] = { [item]: { image: "", status: true, value: "" } }
      }

      return null
    })
  }
  if (e === "10826") {
    return R6_NOTIFICATIONS.map(item => {
      if (newSettings[e]) {
        newSettings[e][item] = { image: "", status: true, value: "" }
      } else {
        newSettings[e] = { [item]: { image: "", status: true, value: "" } }
      }

      return null
    })
  }
  if (e === "21626") {
    return WARZONE_NOTIFICATIONS.map(item => {
      if (newSettings[e]) {
        newSettings[e][item] = { image: "", status: true, value: "" }
      } else {
        newSettings[e] = { [item]: { image: "", status: true, value: "" } }
      }

      return null
    })
  }
  if (e === "21404") {
    return SPLITGATE_NOTIFICATIONS.map(item => {
      if (newSettings[e]) {
        newSettings[e][item] = { image: "", status: true, value: "" }
      } else {
        newSettings[e] = { [item]: { image: "", status: true, value: "" } }
      }

      return null
    })
  }
  if (e === "22328") {
    return CALL_OF_DUTY_MW2_NOTIFICATIONS.map(item => {
      if (newSettings[e]) {
        newSettings[e][item] = { image: "", status: true, value: "" }
      } else {
        newSettings[e] = { [item]: { image: "", status: true, value: "" } }
      }

      return null
    })
  }
  if (e === "23424") {
    return CALL_OF_DUTY_MW3_NOTIFICATIONS.map(item => {
      if (newSettings[e]) {
        newSettings[e][item] = { image: "", status: true, value: "" }
      } else {
        newSettings[e] = { [item]: { image: "", status: true, value: "" } }
      }

      return null
    })
  }
  if (e === "223281") {
    return CALL_OF_DUTY_WARZONE2_NOTIFICATIONS.map(item => {
      if (newSettings[e]) {
        newSettings[e][item] = { image: "", status: true, value: "" }
      } else {
        newSettings[e] = { [item]: { image: "", status: true, value: "" } }
      }

      return null
    })
  }
}
