import React, { FC, useEffect, useState } from "react"

import { Form, Input, Select } from "antd"
import {
  NAVIGATION_DEFAULT,
  NAVIGATION_MOBILE,
} from "components/defaultLinksRouting/default-navigation.constants"
import { useStore } from "stores/utils/use-store"
import useAdvertisers from "domains/internal-offerwall/hooks/useAdvertisers"

export const DefaultLinksRouting: FC<{
  link: string;
  formItemName: string | string[];
  displaySelect?: boolean;
  notImportant?: boolean;
  mobile?: boolean;
}> = ({ link, formItemName, displaySelect = true, notImportant, mobile }) => {
  const [linkSwitch, setlinkSwitch] = useState<
    "external" | "internal-routing" | "raffle" | "promo-popup" | "offerwall"
  >("internal-routing")

  const { dataStore: { promoPopupsStore, rafflesStore, managingJsonStore } } = useStore()
  const { advertisers } = useAdvertisers()

  useEffect(() => {
    const func = async() => {
      if (!promoPopupsStore?.popUps || promoPopupsStore?.popUps?.length === 0) {
        await promoPopupsStore.getData()
      }
      if (!rafflesStore?.raffles || rafflesStore?.raffles?.length === 0) {
        rafflesStore.pagination.limit = 100
      }
      if (!managingJsonStore?.itemTypes || managingJsonStore?.itemTypes?.length === 0) {
        await managingJsonStore.getData()
      }
    }
    func()
  }, [promoPopupsStore, rafflesStore, managingJsonStore, mobile])

  useEffect(() => {
    if (link) {
      if (link.startsWith("promo-popup?id=")) {
        return setlinkSwitch("promo-popup")
      }
      if (link.startsWith("raffle?id=")) {
        return setlinkSwitch("raffle")
      }
      if (link.startsWith("internal?id=")) {
        return setlinkSwitch("internal-routing")
      }
      if (link.startsWith("offerwall")) {
        return setlinkSwitch("offerwall")
      }

      return setlinkSwitch("external")
    }
  }, [link])

  return (
    <div
      style={{
        display: "grid",
        gridAutoFlow: "column",
        gridTemplateColumns: "25% 75%",
        gap: "10px",
      }}
    >
      {displaySelect && (
        <div>
          <Select onChange={setlinkSwitch} value={linkSwitch} style={{ width: "100%" }}>
            <Select.Option key="external" value="external">
              External link
            </Select.Option>
            <Select.Option key="internal-routing" value="internal-routing">
              Internal-routing link
            </Select.Option>
            <Select.Option key="raffle" value="raffle">
              Raffle link
            </Select.Option>
            <Select.Option key="promo-popup" value="promo-popup">
              Promo-popup link
            </Select.Option>
            <Select.Option key="offerwall" value="offerwall">
              Offerwall link
            </Select.Option>
          </Select>
        </div>
      )}
      <Form.Item
        label="Link"
        name={formItemName}
        initialValue={link}
        style={{ width: "100%" }}
        rules={
          notImportant
            ? [{}]
            : [
              {
                required: true,
                message: "Link is required",
              },
            ]
        }
      >
        {linkSwitch === "external" ? (
          <Input value={link} />
        ) : linkSwitch === "promo-popup" ? (
          <Select value={link}>
            {promoPopupsStore?.popUps?.map(action => (
              <Select.Option
                key={`promo-popup?id=${action.id}`}
                value={`promo-popup?id=${action.id}`}
              >
                {action.title}
              </Select.Option>
            ))}
          </Select>
        ) : linkSwitch === "raffle" ? (
          <Select value={link}>
            {rafflesStore?.raffles?.map(action => (
              <Select.Option key={`raffle?id=${action.id}`} value={`raffle?id=${action.id}`}>
                {action.title}
              </Select.Option>
            ))}
          </Select>
        ) : linkSwitch ==="offerwall" ? (
          <Select value={link}>
            {
              advertisers?.map(advertiser => (
                <Select.Option
                  key={`internal?id=offerwall/${advertiser.id}`}
                  value={`internal?id=offerwall/${advertiser.id}`}
                >
                  offerwall/{advertiser.name}
                </Select.Option>
              ))
            }
          </Select>
        ) : (
          <Select value={link}>
            {(mobile ? NAVIGATION_MOBILE : NAVIGATION_DEFAULT).map(action => (
              <Select.Option key={`internal?id=${action}`} value={`internal?id=${action}`}>
                {action}
              </Select.Option>
            ))}
            {!mobile
              && managingJsonStore?.itemTypes
                ?.filter(el => el.type === "contests")
                ?.map(item => {
                  return (
                    <Select.Option
                      key={`internal?id=contest/${item.name}`}
                      value={`internal?id=contest/${item.name}`}
                    >
                      contest/{item.name}
                    </Select.Option>
                  )
                })}
          </Select>
        )}

      </Form.Item>
    </div>
  )
}
