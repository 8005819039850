import { Input, Select } from "antd"
import { ChangeEvent, FC } from "react"

interface SimpleDataInputProps {
    type: "string" | "number" | "boolean";
    value?: any;
    onChange?: (value: any) => void;
}

const SimpleDataInput: FC<SimpleDataInputProps> = ({ type, onChange, value, ...rest }) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.type === "number") {
      const isValidNumber = !isNaN(Number(e.target.value))
      if (isValidNumber) {
        onChange?.(Number(e.target.value))
      } else {
        onChange?.(e.target.value)
      }
    } else {
      onChange?.(e.target.value)
    }
  }
  if (type === "boolean") {
    return (
      <Select {...rest} value={value} onChange={onChange}>
        <Select.Option value={true}>True</Select.Option>
        <Select.Option value={false}>False</Select.Option>
      </Select>
    )
  } else {
    return (
      <Input {...rest} type={type} value={value} onChange={handleChange} />
    )
  }
}

export default SimpleDataInput
