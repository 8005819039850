/* eslint-disable max-len */
import React, { FC, useEffect, useState } from "react"
import { useStore } from "stores/utils/use-store"

import { Button, Form, Divider, Upload, Checkbox, Tabs, InputNumber } from "antd"
import { observer } from "mobx-react"

import { notifications } from "notifications"
import {
  ConfigItemType,
  ContestConfig,
  ContestConfigFreePointsData,
  ContestConfigLiveData,
  ContestConfigOnboardingScreenData,
  InGameNotificationsSettings,
} from "domains/managingJson/store/types"
import moment from "moment"
import { Onboarding } from "./Onboarding"
import { PlusCircleOutlined, UploadOutlined } from "@ant-design/icons"
import { ContestNotStartedSchedule } from "./ContestNotStartedSchedule"
import { ContestFinishedSchedule } from "./ContestFinishedSchedule"
import { Colors } from "./Colors"
import { FreePoints } from "./FreePoints"
import { ContestLive } from "./ContestLive"

import { MainInfoTab } from "./Tabs/main-info-tab"
import { UploadImages } from "./Tabs/uploading-images"
import { TranslationsTab } from "./Tabs/translations-tab"
import { ContestBackEndStuff } from "./ContestServerSideStuff"
import { IngameNotifications } from "./IngameNotifications"
import { FormElement, SubmitCancelButtons } from "components"
import { ChallengeBasedContent } from "./Tabs/challenge-based-content"
import { RewardsConfigurationTab } from "./Tabs/RewardsConfigurationTab"
import { PixelsEditorV2 } from "components/PixelsEditorV2"
import { PointsAwarding } from "./PointsAwarding"

export const EditContest: FC<{
  editType: "create" | "edit";
  onClose: () => void;
  configType: ConfigItemType;
}> = observer(({ editType, configType, onClose }) => {
  const {
    dataStore: {
      managingJsonStore,
      managingJsonStore: { selectedElement: { name, data: contestData } },
    },
  } = useStore()
  const [form] = Form.useForm()
  const isEdit = editType === "edit"
  const [data, setdata] = useState(contestData as ContestConfig)
  const [startDate, setstartDate] = useState(data && data.startDate)
  const [endDate, setendDate] = useState(data && data.endDate)
  const [contestType, setcontestType] = useState((data && data.contestType) || "SCORE_BASED")
  const [paragraps, setparagraps] = useState(data && data?.rulesSection?.paragraps)
  const [addRules, setaddRules] = useState(!!data?.rulesSection)
  const [activeTab, setActiveTab] = useState("1")

  const [topBarLogoIMG, settopBarLogo] = useState(isEdit && data.topBarLogo ? data.topBarLogo : "")
  const [settingsLogoIMG, setsettingsLogo] = useState(isEdit && data.settingsLogo ? data.settingsLogo : "")
  const [mainLogoIMG, setmainLogo] = useState(isEdit && data.mainLogo ? data.mainLogo : "")

  const [advImageIMG, setadvImage] = useState(isEdit && data.advImage ? data.advImage : "")

  const [contestImageIMG, setcontestImage] = useState(isEdit && data?.startgameWindow?.contestImage ? data.startgameWindow.contestImage : "")
  const [endgameFailImageIMG, setendgameFailImage] = useState(isEdit && data?.endgameWindow?.endgameFailImage ? data.endgameWindow.endgameFailImage : "")
  const [endgameSuccessImageIMG, setendgameSuccessImage] = useState(isEdit && data?.endgameWindow?.endgameSuccessImage
    ? data.endgameWindow.endgameSuccessImage
    : "")
  const [endgameFraudImageIMG, setendgameFraudImage] = useState(isEdit && data?.endgameWindow?.endgameFraudImage ? data.endgameWindow.endgameFraudImage : "")
  const [gameBackgroundImageIMG, setGameBackgroundImage] = useState(isEdit && data?.endgameWindow?.gameBackgroundImage ? data.endgameWindow.gameBackgroundImage : "")

  const [inGameNotificationsSettings, setinGameNotificationsSettings]
    = useState<InGameNotificationsSettings>(isEdit && data?.inGameNotificationsSettings ? data?.inGameNotificationsSettings : {})
  const [inGameNotificationsRedefine, setinGameNotificationsRedefine] = useState(isEdit && data?.inGameNotificationsRedefine ? data?.inGameNotificationsRedefine : false)

  // const [loungeSmallImg, setloungeSmallImg] = useState(
  //   isEdit&& data?.loungePageData?.loungeSmallImg
  //     ? data.loungePageData.loungeSmallImg
  //     : '',
  // );
  // const [loungeLargeImg, setloungeLargeImg] = useState(
  //   isEdit&& data?.loungePageData?.loungeLargeImg
  //     ? data.loungePageData.loungeLargeImg
  //     : '',
  // );

  const [leaderboardBanner, setleaderboardBanner] = useState(isEdit && data?.leaderboardBanner ? data.leaderboardBanner : "")

  const [leaderboardTopBanner, setleaderboardTopBanner] = useState(isEdit && data?.leaderboardTopBanner ? data.leaderboardTopBanner : "")

  const [leaderboardAdReplacementBanner, setleaderboardAdReplacementBanner] = useState(isEdit && data?.leaderboardAdReplacementBanner ? data.leaderboardAdReplacementBanner : "")

  const [leaderboardWithCountry, setleaderboardWithCountry] = useState(isEdit ? data?.leaderboardWithCountry : false)

  const [displayAds, setDisplayAds] = useState(isEdit ? data?.displayAds : false)

  const [leaderboardHasGlobal, setleaderboardHasGlobal] = useState(isEdit ? data?.leaderboardHasGlobal : false)

  const [onboardingData, setonboardingData] = useState(isEdit && data.onboarding ? data.onboarding : [{ image: "", text: "", title: "", consentRows: [] }])

  const [contestLiveScheduleData, setcontestLiveScheduleData] = useState(isEdit && data.contestLiveSchedule
    ? data.contestLiveSchedule
    : [
      {
        image: "",
        text: "",
        title: "",
        startDate: new Date(),
        endDate: new Date(),
      },
    ])
  // challenge based contest images
  const [topMainPageLeftBannerIMG, settopMainPageLeftBanner] = useState(isEdit && data?.challengeBasedContest?.topMainPageLeftBanner
    ? data?.challengeBasedContest?.topMainPageLeftBanner
    : "")
  const [topMainPageRightBannerIMG, settopMainPageRightBanner] = useState(isEdit && data?.challengeBasedContest?.topMainPageRightBanner
    ? data?.challengeBasedContest?.topMainPageRightBanner
    : "")
  const [imageNotCompleted1StateIMG, setimageNotCompleted1State] = useState(isEdit && data?.challengeBasedContest?.rightMainPageBanner?.imageNotCompleted1State
    ? data?.challengeBasedContest?.rightMainPageBanner?.imageNotCompleted1State
    : "")
  const [imageCompleted1StateIMG, setimageCompleted1State] = useState(isEdit && data?.challengeBasedContest?.rightMainPageBanner?.imageCompleted1State
    ? data?.challengeBasedContest?.rightMainPageBanner?.imageCompleted1State
    : "")
  const [imageNotCompleteStateIMG, setimageNotCompleteState] = useState(isEdit && data?.challengeBasedContest?.rightMainPageBanner?.imageNotCompleteState
    ? data?.challengeBasedContest?.rightMainPageBanner?.imageNotCompleteState
    : "")
  const [imageGrandStateIMG, setimageGrandState] = useState(isEdit && data?.challengeBasedContest?.rightMainPageBanner?.imageGrandState
    ? data?.challengeBasedContest?.rightMainPageBanner?.imageGrandState
    : "")
  const [leaderboardnotwinIMG, setleaderboardnotwin] = useState(isEdit && data?.challengeBasedContest?.leaderboard?.notwin
    ? data?.challengeBasedContest?.leaderboard?.notwin?.image
    : "")
  const [leaderboardwinIMG, setleaderboardwin] = useState(isEdit && data?.challengeBasedContest?.leaderboard?.win?.image
    ? data?.challengeBasedContest?.leaderboard?.win?.image
    : "")
  const [leaderboardrightIMG, setleaderboardright] = useState(isEdit && data?.challengeBasedContest?.leaderboard?.right?.image
    ? data?.challengeBasedContest?.leaderboard?.right?.image
    : "")
  const [scheduleSidebarBannerImage, setScheduleSidebarBannerImage] = useState(isEdit && data?.scheduleSidebarBannerImage
    ? data?.scheduleSidebarBannerImage : "")

  const [freePointsData, setfreePointsData] = useState(isEdit && data.freePoints ? data.freePoints : [])
  const [leaderboardFullheightSidebar, setleaderboardFullheightSidebar] = useState(isEdit && data?.leaderboardFullheightSidebar ? data?.leaderboardFullheightSidebar : false)
  const [highlightSharing, setHighlightSharing] = useState(isEdit && data?.highlightSharing ? data?.highlightSharing : false)
  const [showAllTimeLeaderboard, setShowAllTimeLeaderboard] = useState(isEdit && data?.showAllTimeLeaderboard ? data?.showAllTimeLeaderboard : false)
  const [recurringNotificationsInterval, setRecurringNotificationsInterval] = useState(isEdit && data?.recurringNotificationsInterval ? data?.recurringNotificationsInterval : null)
  const [inAppPixels, setInAppPixels] = useState(isEdit && data?.inAppPixels ? data?.inAppPixels : [])
  const [startgamePixels, setStartgamePixels] = useState(isEdit && data?.startgamePixels ? data?.startgamePixels : [])
  const [endgamePixels, setEndgamePixels] = useState(isEdit && data?.endgamePixels ? data?.endgamePixels : [])
  const [disableChallengesOnStartgameScreen, setDisableChallengesOnStartgameScreen] = useState(isEdit && data?.disableChallengesOnStartgameScreen ? data?.disableChallengesOnStartgameScreen : false)
  const [automaticHighlightRecordingEnabling, setAutomaticHighlightRecordingEnabling] = useState(isEdit && data?.automaticHighlightRecordingEnabling ? data?.automaticHighlightRecordingEnabling : false)

  useEffect(() => {
    if (data) {
      settopBarLogo(data.topBarLogo || "")
      setmainLogo(data.mainLogo || "")
      // setnavigationLogo(data.navigationLogo || '');
      setsettingsLogo(data.settingsLogo || "")
      setadvImage(data.advImage || "")

      setcontestImage(data.startgameWindow?.contestImage || "")
      setendgameFailImage(data.endgameWindow?.endgameFailImage || "")
      setendgameSuccessImage(data.endgameWindow?.endgameSuccessImage || "")
      setendgameFraudImage(data.endgameWindow?.endgameFraudImage || "")

      setstartDate(data.startDate)
      setendDate(data.endDate)

      // setloungeLargeImg(data.loungePageData?.loungeLargeImg || '');
      // setloungeSmallImg(data.loungePageData?.loungeSmallImg || '');
      setleaderboardBanner(data?.leaderboardBanner || "")
      setleaderboardTopBanner(data?.leaderboardTopBanner || "")
      setleaderboardAdReplacementBanner(data?.leaderboardAdReplacementBanner || "")

      data.onboarding && setonboardingData(data.onboarding)
      data.contestLiveSchedule && setcontestLiveScheduleData(data.contestLiveSchedule)
      data.freePoints && setfreePointsData(data.freePoints)
      data.leaderboardFullheightSidebar && setleaderboardFullheightSidebar(data.leaderboardFullheightSidebar)
      data.highlightSharing && setHighlightSharing(data.highlightSharing)
      data.showAllTimeLeaderboard && setShowAllTimeLeaderboard(data.showAllTimeLeaderboard)
      data.disableChallengesOnStartgameScreen && setDisableChallengesOnStartgameScreen(data.disableChallengesOnStartgameScreen)
      data.automaticHighlightRecordingEnabling && setAutomaticHighlightRecordingEnabling(data.automaticHighlightRecordingEnabling)
      data.inGameNotificationsSettings
        && setinGameNotificationsSettings(data.inGameNotificationsSettings)
      data.inGameNotificationsRedefine
        && setinGameNotificationsRedefine(data.inGameNotificationsRedefine)
      const onb: any = {}

      data.onboarding?.forEach((item, id) => {
        onb[`onboardingTitle${id + 1}`] = item.title
        onb[`onboardingText${id + 1}`] = item.text
        onb[`onboardingImage${id + 1}`] = item.image
        onb[`onboardingConsentRows${id + 1}`] = item.consentRows
      })
      data.freePoints?.forEach((item, id) => {
        onb[`pointsTitle${id + 1}`] = item.title
        onb[`points${id + 1}`] = item.points
        onb[`pointsType${id + 1}`] = item.type
        onb[`pointsText${id + 1}`] = item.text

        onb[`pointsImage${id + 1}`] = item.image
        onb[`pointsRewardText${id + 1}`] = item.rewardText

        onb[`watermark${id + 1}`] = item.watermark
        onb[`watermarkWidth${id + 1}`] = item.watermarkWidth
        onb[`watermarkPosition${id + 1}`] = item.watermarkPosition


        onb[`pointsCTAactiveText${id + 1}`] = item.CTA?.activeText
        onb[`pointsCTAinactiveText${id + 1}`] = item.CTA?.inactiveText

        onb[`pointstopText${id + 1}`] = item.topText

        onb[`pointshighlightsScreenheader${id + 1}`] = item.highlightsScreen?.header
        onb[`pointshighlightsScreendescription${id + 1}`] = item.highlightsScreen?.description
        onb[`pointshighlightsScreenCTAtext${id + 1}`] = item.highlightsScreen?.CTAtext
        onb[`maxFreePoints${id + 1}`] = item.maxFreePoints
        onb[`goalInvite${id + 1}`] = item.goalInvite
      })

      data.contestLiveSchedule?.forEach((item, id) => {
        onb[`contestLiveTitle${id + 1}`] = item.title
        onb[`contestLiveEndDate${id + 1}`] = moment(item.endDate)
        onb[`contestLiveStartDate${id + 1}`] = moment(item.startDate)
        onb[`contestLiveText${id + 1}`] = item.text
        onb[`contestLiveImage${id + 1}`] = item.image
      })

      form.setFieldsValue({
        ...data,
        startDate: moment(data.startDate),
        endDate: moment(data.endDate),
        primaryDark: data.colors?.primary?.dark || "#fff",
        primaryDefault: data.colors?.primary?.default || "#fff",
        primaryLight: data.colors?.primary?.light || "#fff",

        secondaryClick: data.colors?.secondary?.click || "#fff",
        secondaryDefault: data.colors?.secondary?.default || "#fff",
        secondaryHover: data.colors?.secondary?.hover || "#fff",

        backgroundMain: data.colors?.background?.main || "#fff",
        backgroundSecondary: data.colors?.background?.secondary || "#fff",

        neutralBlack: data.colors?.neutral?.black || "#fff",
        neutralGray: data.colors?.neutral?.gray || "#fff",
        neutralInactive: data.colors?.neutral?.inactive || "#fff",
        neutralRed: data.colors?.neutral?.red || "#fff",

        textGray: data.colors?.text?.gray || "#fff",
        textInactive: data.colors?.text?.inactive || "#fff",
        textWhite: data.colors?.text?.white || "#fff",

        ...onb,
      })
    }
  }, [data, form])

  const onFinishHandler = async(event: any) => {
    if (topBarLogoIMG.length === 0) {
      setActiveTab("2")

      return notifications.error("Select topBar Logo")
    }
    if (mainLogoIMG.length === 0) {
      setActiveTab("2")

      return notifications.error("Select main Logo")
    }
    if (settingsLogoIMG.length === 0) {
      setActiveTab("2")

      return notifications.error("Select settings Logo")
    }
    if (advImageIMG.length === 0) {
      setActiveTab("2")

      return notifications.error("Select advertisement img")
    }

    if (contestImageIMG.length === 0) {
      setActiveTab("2")

      return notifications.error("Select Startgame contest Image")
    }
    if (endgameFailImageIMG.length === 0) {
      setActiveTab("2")

      return notifications.error("Select Endgame Fail Image")
    }
    if (endgameSuccessImageIMG.length === 0) {
      setActiveTab("2")

      return notifications.error("Select Endgame Success Image")
    }
    if (endgameFraudImageIMG.length === 0) {
      setActiveTab("2")

      return notifications.error("Select Endgame Fraud Image")
    }
    const onbId = onboardingData.findIndex((_, id) => event[`onboardingImage${id + 1}`] === undefined)
    if (onbId >= 0) {
      setActiveTab("4")

      return notifications.error(`Select onboarding Image ${onbId + 1}`)
    }

    const onboardingArr: ContestConfigOnboardingScreenData[] = onboardingData.map((_, id) => {

      return {
        title: event[`onboardingTitle${id + 1}`],
        text: event[`onboardingText${id + 1}`],
        image: event[`onboardingImage${id + 1}`],
        consentRows: event[`onboardingConsentRows${id + 1}`],
      }
    })
    let freePointsArr: ContestConfigFreePointsData[] = []
    let contestLiveArr: ContestConfigLiveData[] = []
    if (contestType === "SCORE_BASED") {
      const freeId = freePointsData.findIndex((_, id) => event[`pointsImage${id + 1}`] === undefined)
      if (freeId >= 0) {
        setActiveTab("5")

        return notifications.error(`Select pointsImage ${freeId + 1}`)
      }
      freePointsArr = freePointsData.map((_, id) => {
        return {
          title: event[`pointsTitle${id + 1}`],
          text: event[`pointsText${id + 1}`],
          type: event[`pointsType${id + 1}`],

          points: isNaN(Number(event[`points${id + 1}`]))
            ? event[`points${id + 1}`]
            : Number(event[`points${id + 1}`]),

          image: event[`pointsImage${id + 1}`],
          rewardText: event[`pointsRewardText${id + 1}`],

          watermark: event[`watermark${id + 1}`],
          watermarkWidth: event[`watermarkWidth${id + 1}`],
          watermarkPosition: event[`watermarkPosition${id + 1}`],

          CTA: {
            activeText: event[`pointsCTAactiveText${id + 1}`],
            inactiveText: event[`pointsCTAinactiveText${id + 1}`],
          },

          topText: event[`pointstopText${id + 1}`],

          goalInvite: isNaN(Number(event[`goalInvite${id + 1}`]))
            ? event[`goalInvite${id + 1}`]
            : Number(event[`goalInvite${id + 1}`]),
          maxFreePoints: isNaN(Number(event[`maxFreePoints${id + 1}`]))
            ? event[`maxFreePoints${id + 1}`]
            : Number(event[`maxFreePoints${id + 1}`]),

          highlightsScreen: {
            header: event[`pointshighlightsScreenheader${id + 1}`],
            description: event[`pointshighlightsScreendescription${id + 1}`],
            CTAtext: event[`pointshighlightsScreenCTAtext${id + 1}`],
          },
        }
      })

      contestLiveArr = contestLiveScheduleData.map((_, id) => {

        return {
          title: event[`contestLiveTitle${id + 1}`],
          text: event[`contestLiveText${id + 1}`],
          image: event[`contestLiveImage${id + 1}`],
          endDate: event[`contestLiveEndDate${id + 1}`]?.toISOString(),
          startDate: event[`contestLiveStartDate${id + 1}`]?.toISOString(),
        }
      })

      if (!event["contestNotStartedSchedule"]["image"]) {
        setActiveTab("7")

        return notifications.error("Select contestNotStartedSchedule Image")
      }

      if (!event["contestFinishedSchedule"]["image"]) {
        setActiveTab("8")

        return notifications.error("Select contestFinishedSchedule Image")
      }
    }
    const { contestTwitterShareMessage, contestRewardCount } = event
    const data: ContestConfig = {
      prettyName: event.prettyName,
      contestType: event.contestType || "SCORE_BASED",
      topBarLogo: topBarLogoIMG,
      mainLogo: mainLogoIMG,
      // navigationLogo: navigationLogoIMG,
      settingsLogo: settingsLogoIMG,
      advImage: advImageIMG,
      countries: typeof event.countries === "string" ? event.countries.split(",") : event.countries,
      analyticsImpressionInterval: event.analyticsImpressionInterval,
      // services: typeof event.services === 'string' ? event.services.split(',') : event.services,

      termsAndConditionsLink: event.termsAndConditionsLink,
      supportLink: event.supportLink,
      faqLink: event.faqLink,
      startDate: event.startDate.toISOString(),
      endDate: event.endDate.toISOString(),
      colors: {
        primary: {
          default: event.primaryDefault,
          light: event.primaryLight,
          dark: event.primaryDark,
        },
        secondary: {
          default: event.secondaryDefault,
          hover: event.secondaryHover,
          click: event.secondaryClick,
        },
        background: { main: event.backgroundMain, secondary: event.backgroundSecondary },
        neutral: {
          inactive: event.neutralInactive,
          black: event.neutralBlack,
          gray: event.neutralGray,
          red: event.neutralRed,
        },
        text: { inactive: event.textInactive, white: event.textWhite, gray: event.textGray },
      },

      onboarding: onboardingArr,
      contestLiveSchedule: contestLiveArr,
      freePoints: freePointsArr,
      contestNotStartedSchedule: {
        title: event.contestNotStartedSchedule?.title,
        text: event.contestNotStartedSchedule?.text,
        image: event.contestNotStartedSchedule?.image,
      },
      contestFinishedSchedule: {
        title: event.contestFinishedSchedule?.title,
        text: event.contestFinishedSchedule?.text,
        image: event.contestFinishedSchedule?.image,
        CTAtext: event.contestFinishedSchedule?.CTAtext,
      },
      pointAwarding: {
        maxpoints: event.pointAwarding?.maxpoints,
        maxpointsTime: event.pointAwarding?.maxpointsTime,
        secondsToLosePoint: event.pointAwarding?.secondsToLosePoint,
        multiplayerFactor: event.pointAwarding?.multiplayerFactor,
      },
      contestServerSideConfiguration: { contestTwitterShareMessage, contestRewardCount },
      startgameWindow: { contestImage: contestImageIMG },

      endgameWindow: {
        endgameSuccessImage: endgameSuccessImageIMG,
        endgameFailImage: endgameFailImageIMG,
        endgameFraudImage: endgameFraudImageIMG,
        gameBackgroundImage: gameBackgroundImageIMG,
      },

      splashScreen: event.splashScreen,
      onboardingCTA: event.onboardingCTA,
      errorStateGlobal: event.errorStateGlobal,
      schedule: event.schedule,
      leaderboard: event.leaderboard,
      rewards: event.rewards,
      invitePopup: event.invitePopup,
      tooltipData: event.tooltipData,
      rulesSection: addRules && { ...event.rulesSection, paragraps },
      loungePageData: { games: event?.loungePageData?.games },
      leaderboardBanner,
      leaderboardTopBanner,
      leaderboardAdReplacementBanner,
      leaderboardWithCountry,
      displayAds,
      leaderboardHasGlobal,
      leaderboardBanners: {
        topBannerCopyText: event?.leaderboardBanners.topBannerCopyText,
        rightBannerCopyText: event?.leaderboardBanners.rightBannerCopyText,
      },
      topLadderRewardStrategy: {
        type: event?.topLadderRewardStrategy?.type,
        rewardText: event?.topLadderRewardStrategy?.rewardText,
        buffPointsAmount: event?.topLadderRewardStrategy?.buffPointsAmount,
        giftCardText: event?.topLadderRewardStrategy?.giftCardText,
      },
      inGameNotificationsSettings,
      inGameNotificationsRedefine,
      challengeBasedContest: {
        topMainPageLeftBanner: topMainPageLeftBannerIMG,
        topMainPageRightBanner: topMainPageRightBannerIMG,
        rightMainPageBanner: {
          imageCompleted1State: imageCompleted1StateIMG,
          imageNotCompleted1State: imageNotCompleted1StateIMG,
          title1State: event?.challengeBasedContest?.rightMainPageBanner?.title1State,
          text1State: event?.challengeBasedContest?.rightMainPageBanner?.text1State,
          CTAtext1State: event?.challengeBasedContest?.rightMainPageBanner?.CTAtext1State,

          imageNotCompleteState: imageNotCompleteStateIMG,
          titleNotCompleteState:
            event?.challengeBasedContest?.rightMainPageBanner?.titleNotCompleteState,
          textNotCompleteState:
            event?.challengeBasedContest?.rightMainPageBanner?.textNotCompleteState,
          CTAtextNotCompleteState:
            event?.challengeBasedContest?.rightMainPageBanner?.CTAtextNotCompleteState,

          imageGrandState: imageGrandStateIMG,
          titleGrandState: event?.challengeBasedContest?.rightMainPageBanner?.titleGrandState,
          textGrandState: event?.challengeBasedContest?.rightMainPageBanner?.textGrandState,
          CTAtextGrandState: event?.challengeBasedContest?.rightMainPageBanner?.CTAtextGrandState,
        },
        leaderboard: {
          notwin: {
            CTAtext: event?.challengeBasedContest?.leaderboard?.notwin?.CTAtext,
            image: leaderboardnotwinIMG,
            text: event?.challengeBasedContest?.leaderboard?.notwin?.text,
            title: event?.challengeBasedContest?.leaderboard?.notwin?.title,
          },
          win: {
            CTAtext: event?.challengeBasedContest?.leaderboard?.win?.CTAtext,
            title: event?.challengeBasedContest?.leaderboard?.win?.title,
            image: leaderboardwinIMG,
            text: event?.challengeBasedContest?.leaderboard?.win?.text,
          },
          right: {
            titleFinished: event?.challengeBasedContest?.leaderboard?.right?.titleFinished,
            title: event?.challengeBasedContest?.leaderboard?.right?.title,
            text: event?.challengeBasedContest?.leaderboard?.right?.text,
            image: leaderboardrightIMG,
          },
        },
      },
      grandPrizeRewards: event?.grandPrizeRewards,
      scheduleSidebarBannerImage,
      leaderboardFullheightSidebar,
      highlightSharing,
      showAllTimeLeaderboard,
      disableChallengesOnStartgameScreen,
      automaticHighlightRecordingEnabling,
      inAppPixels,
      startgamePixels,
      endgamePixels,
      recurringNotificationsInterval,
      disclaimer: event?.disclaimer,
    }
    managingJsonStore.funcAfterSuccess = async() => {
      onClose()
      await managingJsonStore.getData()
    }
    editType === "edit"
      ? await managingJsonStore.update({
        name: event.name,
        type: configType,
        data,
      })
      : await managingJsonStore.create({
        name: event.name,
        type: configType,
        data,
      })
  }
  const addOnboarding = () => {
    setonboardingData([...onboardingData, { image: "", text: "", title: "", consentRows: [] }])
  }
  const addFreePoints = () => {
    setfreePointsData([...freePointsData, {} as ContestConfigFreePointsData])
  }

  const addContestLive = () => {
    setcontestLiveScheduleData([
      ...contestLiveScheduleData,
      {
        text: "",
        title: "",
        image: "",
        endDate: new Date(),
        startDate: new Date(),
      },
    ])
  }

  const deleteFunc = (
    id: number,
    dependingData: any,
    type: "freePoint" | "onboarding" | "contestLive",
  ) => {
    const arr = [...dependingData].filter((_, idx) => idx !== id)
    const onb = {}

    if (type === "freePoint") {
      setfreePointsData(arr)
      arr.forEach((item, id) => {
        onb[`pointsTitle${id + 1}`] = item.title
        onb[`points${id + 1}`] = item.points
        onb[`pointsType${id + 1}`] = item.type
        onb[`pointsText${id + 1}`] = item.text
      })
    } else if (type === "onboarding") {
      setonboardingData(arr)
      arr.forEach((item, id) => {
        onb[`onboardingTitle${id + 1}`] = item.title
        onb[`onboardingText${id + 1}`] = item.text
        onb[`onboardingImage${id + 1}`] = item.image
        onb[`onboardingConsentRows${id + 1}`] = item.consentRows
      })
    } else if (type === "contestLive") {
      setcontestLiveScheduleData(arr)
      arr.forEach((item, id) => {
        onb[`contestLiveTitle${id + 1}`] = item.title
        onb[`contestLiveEndDate${id + 1}`] = moment(item.endDate)
        onb[`contestLiveStartDate${id + 1}`] = moment(item.startDate)
        onb[`contestLiveText${id + 1}`] = item.text
        onb[`contestLiveImage${id + 1}`] = item.image
      })
    }

    form.setFieldsValue({ ...onb })
  }

  return (
    <>
      <Upload
        accept=".json"
        showUploadList={false}
        beforeUpload={file => {
          const reader = new FileReader()

          reader.onload = (e: any) => {
            setdata(JSON.parse(e.target.result))
          }
          reader.readAsText(file)

          // Prevent upload
          return false
        }}
      >
        <Button
          id="top"
          icon={<UploadOutlined />}
          style={{
            display: "flex",
            placeItems: "center",
            marginBottom: "20px",
            width: "750px",
            justifyContent: "center",
          }}
          type="primary"
        >
          Upload data by JSON File
        </Button>
      </Upload>
      <Form
        onFinish={onFinishHandler}
        form={form}
        onFinishFailed={e => {
          if (e.errorFields && e?.errorFields[0].name[0]) {
            const value = String(e?.errorFields[0].name[0])
            if (
              [
                "name",
                "prettyName",
                "contestType",
                "loungePageData", //games
                "countries",
                "startDate",
                "endDate",
                "analyticsImpressionInterval",
                "services",
                "termsAndConditionsLink",
                "supportLink",
              ].includes(value)
            ) {
              setActiveTab("1")
            }
            if (value.includes("onboarding")) {
              setActiveTab("4")
            }
            if (value.includes("points") || value.includes("Points")) {
              setActiveTab("5")
            }
            if (value.includes("contestLive")) {
              setActiveTab("6")
            }
            if (value.includes("contestNotStartedSchedule")) {
              setActiveTab("7")
            }
            if (value.includes("contestFinishedSchedule")) {
              setActiveTab("8")
            }
            if (["contestTwitterShareMessage", "contestRewardCount"].includes(value)) {
              setActiveTab("9")
            }
            if (value.includes("rulesSection")) {
              setActiveTab("10")
            }
          }
          notifications.error("Fill all fields!")
        }}
        id="contestForm"
      >
        <Tabs activeKey={activeTab} onChange={setActiveTab}>
          <Tabs.TabPane forceRender tab="Main-info" key="1">
            <MainInfoTab
              name={name}
              editType={editType}
              setstartDate={setstartDate}
              setendDate={setendDate}
              startDate={startDate}
              endDate={endDate}
              data={data}
              contestType={contestType}
              setcontestType={setcontestType}
            />

            {contestType === "CHALLENGE_BASED" && (
              <FormElement
                type="input"
                name="disclaimer"
                label="Disclaimer at the bottom of challenges"
              />
            )}

            {contestType === "SCORE_BASED" && (
              <>
                <div style={{ display: "flex", gap: 20 }}>
                  <Form.Item name="leaderboardWithCountry" label="Leaderboard with country?">
                    <Checkbox
                      checked={leaderboardWithCountry}
                      onChange={() => setleaderboardWithCountry(!leaderboardWithCountry)}
                    />
                  </Form.Item>
                  {leaderboardWithCountry && (
                    <Form.Item name="leaderboardHasGlobal" label="All countries leaderboard">
                      <Checkbox
                        checked={leaderboardHasGlobal}
                        onChange={() => setleaderboardHasGlobal(!leaderboardHasGlobal)}
                      />
                    </Form.Item>
                  )}
                </div>

                <Form.Item name="enableAds" label="Enable ads">
                  <Checkbox
                    checked={displayAds}
                    onChange={() => setDisplayAds(prev => !prev)}
                  />
                </Form.Item>
                <Form.Item name="leaderboardFullheightSidebar" label="Leaderboard fullheight sidebar">
                  <Checkbox
                    checked={leaderboardFullheightSidebar}
                    onChange={() => setleaderboardFullheightSidebar(!leaderboardFullheightSidebar)}
                  />
                </Form.Item>

                <Form.Item name="highlightSharing" label="Enable highlight sharing">
                  <Checkbox
                    checked={highlightSharing}
                    onChange={() => setHighlightSharing(prev => !prev)}
                  />
                </Form.Item>
              </>
            )}
            <div>
              <Form.Item name="showAllTimeLeaderboard" label="Show all time leaderboard">
                <Checkbox
                  checked={showAllTimeLeaderboard}
                  onChange={() => setShowAllTimeLeaderboard(prev => !prev)}
                />
              </Form.Item>
              <Form.Item name="recurringNotificationsInterval" label="Interval in which all recurring notifications should fire (seconds)">
                <InputNumber
                  value={recurringNotificationsInterval}
                  onChange={setRecurringNotificationsInterval}
                />
              </Form.Item>
              <Form.Item name="disableChallengesOnStartgameScreen" label="Disable challenges on startgame screen">
                <Checkbox
                  checked={disableChallengesOnStartgameScreen}
                  onChange={() => setDisableChallengesOnStartgameScreen(prev => !prev)}
                />
              </Form.Item>
              <Form.Item name="automaticHighlightRecording" label="Enable highlight recording automatically during contest onboarding">
                <Checkbox
                  checked={automaticHighlightRecordingEnabling}
                  onChange={() => setAutomaticHighlightRecordingEnabling(prev => !prev)}
                />
              </Form.Item>
            </div>
            <Divider />
            <div>
              <h2>In-app pixels</h2>
              <div style={{ marginBottom: 18 }}>Fired whenever branded image from contest is shown</div>
              <PixelsEditorV2 pixels={inAppPixels} onChange={setInAppPixels} />
            </div>
            <Divider />
            <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
              <h2>Startgame window pixels</h2>
              <PixelsEditorV2 pixels={startgamePixels} onChange={setStartgamePixels} />
            </div>
            <Divider />
            <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
              <h2>Endgame window pixels</h2>
              <PixelsEditorV2 pixels={endgamePixels} onChange={setEndgamePixels} />
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane forceRender tab="Upload images" key="2">
            <UploadImages
              topBarLogoIMG={topBarLogoIMG}
              settopBarLogo={settopBarLogo}
              mainLogoIMG={mainLogoIMG}
              setmainLogo={setmainLogo}
              settingsLogoIMG={settingsLogoIMG}
              setsettingsLogo={setsettingsLogo}
              advImageIMG={advImageIMG}
              setadvImage={setadvImage}
              contestImageIMG={contestImageIMG}
              setcontestImage={setcontestImage}
              endgameSuccessImageIMG={endgameSuccessImageIMG}
              setendgameSuccessImage={setendgameSuccessImage}
              endgameFraudImageIMG={endgameFraudImageIMG}
              setendgameFraudImage={setendgameFraudImage}
              endgameFailImageIMG={endgameFailImageIMG}
              setendgameFailImage={setendgameFailImage}
              gameBackgroundImageIMG={gameBackgroundImageIMG}
              setGameBackgroundImage={setGameBackgroundImage}
              leaderboardBanner={leaderboardBanner}
              setleaderboardBanner={setleaderboardBanner}
              leaderboardTopBanner={leaderboardTopBanner}
              setleaderboardTopBanner={setleaderboardTopBanner}
              leaderboardAdReplacementBanner={leaderboardAdReplacementBanner}
              setleaderboardAdReplacementBanner={setleaderboardAdReplacementBanner}
              scheduleSidebarBanner={scheduleSidebarBannerImage}
              setScheduleSidebarBanner={setScheduleSidebarBannerImage}
            />
          </Tabs.TabPane>
          <Tabs.TabPane forceRender tab="Colors" key="3">
            <Colors data={data} />
          </Tabs.TabPane>

          <Tabs.TabPane forceRender tab="Onboarding" key="4">
            <Divider>Onboarding</Divider>
            {onboardingData?.map((item, id) => (
              <Onboarding
                data={item}
                id={id + 1}
                key={id}
                deleteFunc={() => deleteFunc(id, onboardingData, "onboarding")}
                form={form}
              />
            ))}
            <div
              onClick={addOnboarding}
              style={{ display: "flex", gap: "10px", alignItems: "center", cursor: "pointer" }}
            >
              <div>Add onboarding page</div>
              <PlusCircleOutlined />
            </div>
          </Tabs.TabPane>

          {contestType === "SCORE_BASED" && (
            <>
              <Tabs.TabPane forceRender tab="Free points" key="5">
                <Divider>Free points</Divider>
                {freePointsData?.map((item, id) => (
                  <FreePoints
                    data={item}
                    form={form}
                    id={id + 1}
                    key={id}
                    deleteFunc={() => deleteFunc(id, freePointsData, "freePoint")}
                  />
                ))}
                <div
                  onClick={addFreePoints}
                  style={{ display: "flex", gap: "10px", alignItems: "center", cursor: "pointer" }}
                >
                  <div>Add free points</div>
                  <PlusCircleOutlined />
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane forceRender tab="Contest Live" key="6">
                <Divider>Contest Live </Divider>
                {contestLiveScheduleData?.map((item, id) => (
                  <ContestLive
                    data={item}
                    form={form}
                    id={id + 1}
                    key={id}
                    deleteFunc={() => deleteFunc(id, freePointsData, "contestLive")}
                  />
                ))}
                <div
                  onClick={addContestLive}
                  style={{ display: "flex", gap: "10px", alignItems: "center", cursor: "pointer" }}
                >
                  <div>Add contest Live Schedule</div>
                  <PlusCircleOutlined />
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane forceRender tab="Contest Not Started" key="7">
                <ContestNotStartedSchedule data={data?.contestNotStartedSchedule} form={form} />
              </Tabs.TabPane>
              <Tabs.TabPane forceRender tab="Contest Finished" key="8">
                <ContestFinishedSchedule data={data?.contestFinishedSchedule} form={form} />
              </Tabs.TabPane>
              <Tabs.TabPane forceRender tab="Server Side Configuration" key="9">
                <ContestBackEndStuff data={data?.contestServerSideConfiguration} />
              </Tabs.TabPane>
              <Tabs.TabPane forceRender tab="Points awarding" key="14">
                <PointsAwarding data={data?.pointAwarding} form={form} />
              </Tabs.TabPane>
            </>
          )}
          <Tabs.TabPane forceRender tab="Translations" key="10">
            <TranslationsTab
              type={data.contestType}
              data={data}
              setparagraps={setparagraps}
              addRules={addRules}
              setaddRules={setaddRules}
            />
          </Tabs.TabPane>
          {contestType === "CHALLENGE_BASED" && (
            <Tabs.TabPane forceRender tab="Contest data" key="12">
              <ChallengeBasedContent
                topMainPageLeftBannerIMG={topMainPageLeftBannerIMG}
                settopMainPageLeftBanner={settopMainPageLeftBanner}
                topMainPageRightBannerIMG={topMainPageRightBannerIMG}
                settopMainPageRightBanner={settopMainPageRightBanner}
                imageCompleted1StateIMG={imageCompleted1StateIMG}
                setimageCompleted1State={setimageCompleted1State}
                imageNotCompleted1StateIMG={imageNotCompleted1StateIMG}
                setimageNotCompleted1State={setimageNotCompleted1State}
                imageNotCompleteStateIMG={imageNotCompleteStateIMG}
                setimageNotCompleteState={setimageNotCompleteState}
                imageGrandStateIMG={imageGrandStateIMG}
                setimageGrandState={setimageGrandState}
                leaderboardnotwinIMG={leaderboardnotwinIMG}
                setleaderboardnotwin={setleaderboardnotwin}
                leaderboardwinIMG={leaderboardwinIMG}
                setleaderboardwin={setleaderboardwin}
                leaderboardrightIMG={leaderboardrightIMG}
                setleaderboardright={setleaderboardright}
              />
            </Tabs.TabPane>
          )}
          <Tabs.TabPane forceRender tab="Ingame notifications" key="11">
            <IngameNotifications
              form={form}
              opened={activeTab === "11"}
              inGameNotificationsSettings={inGameNotificationsSettings}
              setinGameNotificationsSettings={setinGameNotificationsSettings}
              redefine={inGameNotificationsRedefine}
              setredefine={setinGameNotificationsRedefine}
            />
          </Tabs.TabPane>
          {contestType === "SCORE_BASED" && (
            <Tabs.TabPane forceRender tab="Rewards Configuration" key="13">
              <Form.Item name='grandPrizeRewards' noStyle>
                <RewardsConfigurationTab games={data?.loungePageData?.games ?? []} data={data?.grandPrizeRewards ?? {}} />
              </Form.Item>
            </Tabs.TabPane>
          )}
        </Tabs>

        <SubmitCancelButtons onClose={onClose} isLoading={managingJsonStore.isLoading} />
      </Form>
    </>
  )
})
