import { checkIsUploadableImage } from "utils/before-upload"
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons"
import { Input, Upload } from "antd"
import React, { FC } from "react"
import { useStore } from "stores/utils/use-store"

export const UploadComponent: FC<{
  image: string;
  style?: React.CSSProperties;
  setImage: React.Dispatch<React.SetStateAction<string>> | ((image: string) => void);
  placeholder?: string;
  title?: string;
  imgStyle?: React.CSSProperties;
  uploadStyle?: { listType?: "picture-card" | "picture"; className?: "avatar-uploader" };
  placeHolderStyle?: React.CSSProperties;
  maxSize?: number; //MB
  required?: boolean;
  requiredError?: boolean;
  name?: string;
}> = ({ image, setImage, style, imgStyle, uploadStyle, maxSize, placeholder, title, required, requiredError, name }) => {
  const imgStl = imgStyle || { width: "70px", height: "70px" }
  const uplStl = uploadStyle || { className: "avatar-uploader", listType: "picture-card" }

  const {
    dataStore: {
      filesStore: { isLoading },
      filesStore,
    },
  } = useStore()

  const getImagePreview = () => {
    if (image.endsWith("webm")) return (<video
      style={imgStl}
      src={image}
      autoPlay
      muted
      playsInline
      loop
    />)

    return (<img src={image} style={imgStl} alt="avatar" />)
  }

  return (
    <div style={style}>
      {title && (
        <div style={{ fontWeight: "500" }}>
          {title}
          {required && <span style={{ color: "red" }}> *</span>}
        </div>
      )}
      <Upload
        name={name}
        showUploadList={false}
        className={!image ? "avatar-uploader" : uplStl.className}
        listType={!image ? "picture-card" : uplStl.listType}
        multiple={true}
        action={async file => {
          const formData = new FormData()
          formData.append("file", file)
          const url = await filesStore.uploadImageAndGetUrl(formData)
          setImage(url)

          return url
        }}
        beforeUpload={file => checkIsUploadableImage(file, maxSize)}
      >

        {image && getImagePreview()}
        {!image && isLoading && <LoadingOutlined />}
        {!image && !isLoading && <PlusOutlined />}
        {!image && <div className="ant-upload-text">{placeholder || "Upload"}</div>}

      </Upload>
      {requiredError && <div style={{ color: "red", marginBottom: 4 }}>Image is required</div>}
      <Input
        value={image}
        onChange={e => setImage(e.target.value)}
        placeholder="Select image or input link"
        status={requiredError ? "error" : undefined}
      />
    </div>
  )
}
