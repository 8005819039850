import { InGameNotificationsSettings, Pixel } from "domains/managingJson/store/types"
import { NotificationsEditor } from "./components/NotificationEditor"
import {
  LOL_NOTIFICATIONS,
  FORTNITE_NOTIFICATIONS,
  DOTA_NOTIFICATIONS,
  VALORANT_NOTIFICATIONS,
  MINECRAFT_NOTIFICATIONS,
  FALL_GUYS_NOTIFICATIONS,
  ROCKET_LEAGUE_NOTIFICATIONS,
  HALO_NOTIFICATIONS,
  HEARTHSTONE_NOTIFICATIONS,
  SPLITGATE_NOTIFICATIONS,
  R6_NOTIFICATIONS,
  WARZONE_NOTIFICATIONS,
  CALL_OF_DUTY_MW2_NOTIFICATIONS,
  CALL_OF_DUTY_MW3_NOTIFICATIONS,
  CALL_OF_DUTY_WARZONE2_NOTIFICATIONS,
} from "./ingameNotifications"
import { Divider } from "antd"


type GameSettingsProps = {
  inGameNotificationsSettings: InGameNotificationsSettings;
  setinGameNotificationsSettings: (data: InGameNotificationsSettings) => void;
};

export const GameSettings = ({
  inGameNotificationsSettings,
  setinGameNotificationsSettings,
}: GameSettingsProps) => {
  const updateData = (
    game: string,
    title: string,
    type: "image" | "status" | "value" | "pixels",
    value: string | boolean | Pixel[],
  ) => {
    const newSettings = { ...inGameNotificationsSettings }
    if (newSettings[game]) {
      if (newSettings[game][title]) {
        if (type === "status") {
          newSettings[game][title].status = value as boolean
        } else if (type === "pixels") {
          newSettings[game][title].pixels = value as Pixel[]
        } else {
          newSettings[game][title][type] = value as string
        }
      } else {
        newSettings[game][title] = { status: !!value, image: "", pixels: [], value: "" }
      }
    }
    setinGameNotificationsSettings(newSettings)
  }


  return (
    <div>
      {Object.keys(inGameNotificationsSettings)?.map(e => {
        if (e === "5426") {
          return (
            <div key={e}>
              <Divider>LOL Notifications</Divider>
              <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 20 }}>
                {LOL_NOTIFICATIONS.map((item, index) => (
                  <NotificationsEditor
                    key={index}
                    game={e}
                    inGameNotificationsSettings={inGameNotificationsSettings}
                    title={item}
                    updateData={updateData}
                  />
                ))}
              </div>
            </div>
          )
        }

        if (e === "21216") {
          return (
            <div key={e}>
              <Divider>Fortnite Notifications</Divider>
              <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 20 }}>
                {FORTNITE_NOTIFICATIONS.map((item, index) => (
                  <NotificationsEditor
                    key={index}
                    game={e}
                    inGameNotificationsSettings={inGameNotificationsSettings}
                    title={item}
                    updateData={updateData}
                  />
                ))}
              </div>
            </div>
          )
        }

        if (e === "8032") {
          return (
            <div key={e}>
              <Divider>Minecraft Notifications</Divider>
              <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 20 }}>
                {MINECRAFT_NOTIFICATIONS.map((item, index) => (
                  <NotificationsEditor
                    key={index}
                    game={e}
                    inGameNotificationsSettings={inGameNotificationsSettings}
                    title={item}
                    updateData={updateData}
                  />
                ))}
              </div>
            </div>
          )
        }

        if (e === "21640") {
          return (
            <div key={e}>
              <Divider>Valorant Notifications</Divider>
              <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 20 }}>
                {VALORANT_NOTIFICATIONS.map((item, index) => (
                  <NotificationsEditor
                    key={index}
                    game={e}
                    inGameNotificationsSettings={inGameNotificationsSettings}
                    title={item}
                    updateData={updateData}
                  />
                ))}
              </div>
            </div>
          )
        }
        if (e === "7314") {
          return (
            <div key={e}>
              <Divider>Dota Notifications</Divider>
              <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 20 }}>
                {DOTA_NOTIFICATIONS.map((item, index) => (
                  <NotificationsEditor
                    key={index}
                    game={e}
                    inGameNotificationsSettings={inGameNotificationsSettings}
                    title={item}
                    updateData={updateData}
                  />
                ))}
              </div>
            </div>
          )
        }
        if (e === "42424") {
          return (
            <div key={e}>
              <Divider>Fall Guys Notifications</Divider>
              <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 20 }}>
                {FALL_GUYS_NOTIFICATIONS.map((item, index) => (
                  <NotificationsEditor
                    key={index}
                    game={e}
                    inGameNotificationsSettings={inGameNotificationsSettings}
                    title={item}
                    updateData={updateData}
                  />
                ))}
              </div>
            </div>
          )
        }
        if (e === "10798") {
          return (
            <div key={e}>
              <Divider>Rocket League Notifications</Divider>
              <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 20 }}>
                {ROCKET_LEAGUE_NOTIFICATIONS.map((item, index) => (
                  <NotificationsEditor
                    key={index}
                    game={e}
                    inGameNotificationsSettings={inGameNotificationsSettings}
                    title={item}
                    updateData={updateData}
                  />
                ))}
              </div>
            </div>
          )
        }

        if (e === "21854") {
          return (
            <div key={e}>
              <Divider>Halo infinite Notifications</Divider>
              <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 20 }}>
                {HALO_NOTIFICATIONS.map((item, index) => (
                  <NotificationsEditor
                    key={index}
                    game={e}
                    inGameNotificationsSettings={inGameNotificationsSettings}
                    title={item}
                    updateData={updateData}
                  />
                ))}
              </div>
            </div>
          )
        }
        if (e === "9898") {
          return (
            <div key={e}>
              <Divider>Hearthstone Notifications</Divider>
              <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 20 }}>
                {HEARTHSTONE_NOTIFICATIONS.map((item, index) => (
                  <NotificationsEditor
                    key={index}
                    game={e}
                    inGameNotificationsSettings={inGameNotificationsSettings}
                    title={item}
                    updateData={updateData}
                  />
                ))}
              </div>
            </div>
          )
        }

        if (e === "10826") {
          return (
            <div key={e}>
              <Divider>Tom Clancy&#39;s Rainbow Six: Siege</Divider>
              <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 20 }}>
                {R6_NOTIFICATIONS.map((item, index) => (
                  <NotificationsEditor
                    key={index}
                    game={e}
                    inGameNotificationsSettings={inGameNotificationsSettings}
                    title={item}
                    updateData={updateData}
                  />
                ))}
              </div>
            </div>
          )
        }
        if (e === "21626") {
          return (
            <div key={e}>
              <Divider>Call of Duty: Modern Warfare</Divider>
              <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 20 }}>
                {WARZONE_NOTIFICATIONS.map((item, index) => (
                  <NotificationsEditor
                    key={index}
                    game={e}
                    inGameNotificationsSettings={inGameNotificationsSettings}
                    title={item}
                    updateData={updateData}
                  />
                ))}
              </div>
            </div>
          )
        }
        if (e === "22328") {
          return (
            <div key={e}>
              <Divider>Call of Duty: Modern Warfare II</Divider>
              <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 20 }}>
                {CALL_OF_DUTY_MW2_NOTIFICATIONS.map((item, index) => (
                  <NotificationsEditor
                    key={index}
                    game={e}
                    inGameNotificationsSettings={inGameNotificationsSettings}
                    title={item}
                    updateData={updateData}
                  />
                ))}
              </div>
            </div>
          )
        }
        if (e === "23424") {
          return (
            <div key={e}>
              <Divider>Call Of Duty: Modern Warfare III</Divider>
              <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 20 }}>
                {CALL_OF_DUTY_MW3_NOTIFICATIONS.map((item, index) => (
                  <NotificationsEditor
                    key={index}
                    game={e}
                    inGameNotificationsSettings={inGameNotificationsSettings}
                    title={item}
                    updateData={updateData}
                  />
                ))}
              </div>
            </div>
          )
        }
        if (e === "223281") {
          return (
            <div key={e}>
              <Divider>Call of Duty: Warzone 2</Divider>
              <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 20 }}>
                {CALL_OF_DUTY_WARZONE2_NOTIFICATIONS.map((item, index) => (
                  <NotificationsEditor
                    key={index}
                    game={e}
                    inGameNotificationsSettings={inGameNotificationsSettings}
                    title={item}
                    updateData={updateData}
                  />
                ))}
              </div>
            </div>
          )
        }

        if (e === "21404") {
          return (
            <div key={e}>
              <Divider>Splitgate Notifications</Divider>
              <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 20 }}>
                {SPLITGATE_NOTIFICATIONS.map((item, index) => (
                  <NotificationsEditor
                    key={index}
                    game={e}
                    inGameNotificationsSettings={inGameNotificationsSettings}
                    title={item}
                    updateData={updateData}
                  />
                ))}
              </div>
            </div>
          )
        }

        return null
      })}
    </div>
  )
}
