import { DeleteOutlined } from "@ant-design/icons"
import Icon from "@ant-design/icons/lib/components/Icon"
import { gameNamesDesktop } from "abstract/constants/game-ids"
import { Button, FormInstance } from "antd"
import { FormElement, UploadComponent } from "components"
import { ContestConfig } from "domains/managingJson/store/types"
import { FC, useState } from "react"
import { useStore } from "stores/utils/use-store"

interface props {
  game: string
  event: string
  form: FormInstance<any>
}

export const GameNotificationPicture: FC<props> = ({ game, event, form }) => {

  const { dataStore: { managingJsonStore: { selectedElement } } } = useStore()
  const data = selectedElement.data as ContestConfig
  const [gameLogo, setGameLogo] = useState(data.inGameNotificationsSettings
    ? data.inGameNotificationsSettings[game][event].image : "")

  const [pixels, setPixels] = useState(data.inGameNotificationsSettings
    ? data.inGameNotificationsSettings[game][event].pixels ?? [] : [])

  const setPicture = (e: string) => {
    if (!data.inGameNotificationsSettings
      || !data.inGameNotificationsSettings[game]
      || !data.inGameNotificationsSettings[game][event]) {
      return
    }

    data.inGameNotificationsSettings[game][event].image = e
    setGameLogo(e)
  }

  const addPixel = () => {
    if (!data.inGameNotificationsSettings
      || !data.inGameNotificationsSettings[game]
      || !data.inGameNotificationsSettings[game][event]) {
      return
    }
    setPixels([...pixels, { src: "" }])

  }

  const deletePixel = (index: number) => {
    const pixelsFiltered = pixels.filter((_, i) => i !== index)
    setPixels(pixelsFiltered)
    for (let i = index; i < pixelsFiltered.length; i++) {
      form.setFieldValue(`${gameNamesDesktop[game]}${i+1}Pixel${event}Interval`, pixelsFiltered[i].firingInterval)
      form.setFieldValue(`${gameNamesDesktop[game]}${i+1}Pixel${event}URL`, pixelsFiltered[i].src)
    }
  }

  const changeInterval = (e, index) => {
    pixels[index].firingInterval = e.target.value
    setPixels([...pixels])

    if (!data.inGameNotificationsSettings
      || !data.inGameNotificationsSettings[game]
      || !data.inGameNotificationsSettings[game][event]) {
      return
    }

    data.inGameNotificationsSettings[game][event].pixels = pixels

  }

  const changePixelSrc = (e, index) => {
    pixels[index].src = e.target.value
    setPixels([...pixels])

    if (!data.inGameNotificationsSettings
      || !data.inGameNotificationsSettings[game]
      || !data.inGameNotificationsSettings[game][event]) {
      return
    }

    data.inGameNotificationsSettings[game][event].pixels = pixels

  }

  return (
    <div>
      <UploadComponent
        image={gameLogo}
        setImage={setPicture}
        placeholder={event}
        title={event}
        required
      />
      <div style={{ display: "flex", alignItems: "center", margin: "20px 0px" }}>
        <div style={{ marginRight: "auto" }}>Tracking pixels</div>
        <Button
          onClick={addPixel}
          type="primary"
          style={{
            cursor: "pointer",
            backgroundColor: "#1690ff",
            borderColor: "#1690ff",
          }}
        >Add new pixel
        </Button>
      </div>
      {pixels.map((pixel, index) => (
        <div key={`${event}${index}`} style={{ display: "flex", alignItems: "center" }}>
          <div style={{ width: "49%", marginRight: "10px" }}>
            {index + 1}. Pixel URL
            <FormElement
              type="input"
              label=""
              name={`${gameNamesDesktop[game]}${index+1}Pixel${event}URL`}
              initialValue={pixel.src}
              props={{
                onChange: e => {
                  changePixelSrc(e, index)
                },
              }}
            />
          </div>
          <div style={{ width: "30%", marginRight: "10px" }}>
            Interval (s)
            <FormElement
              type="input"
              label=""
              name={`${gameNamesDesktop[game]}${index+1}Pixel${event}Interval`}
              initialValue={pixel.firingInterval}
              props={{
                onChange: e => {
                  changeInterval(e, index)
                },
              }}
            />
          </div>
          <DeleteOutlined
            style={{ color: "#FF0000", fontSize: "20px", cursor: "pointer" }}
            onClick={() => {
              deletePixel(index)
            }}
          />
        </div>
      ))}
    </div>)
}
